<template>
    <div>
        <el-dialog title="" :visible.sync="dialogVisible" width="500px" :before-close="handleClose">
            <div class="title"><span><img src="@/assets/images/plan/del.png" class="img" alt=""></span>确认删除这行内容？</div>
            <div class="content">确认后，内容将无法找回</div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleClose" style="color:#B9B9B9;border: 1px solid #656565;background: #262626;">取 消</el-button>
                <el-button type="primary" @click="ok">确 认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handleClose() {
            this.$emit('close')
        },
        ok() {
            this.$emit('ok')
        }
    }
}
</script>

<style lang="scss" scoped>
.img {
    width: 30px;
    height: 30px;
}

.title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 20px;
    line-height: 30px;

    span {
        height: 30px;
        margin-right: 15px;
    }
}

.content {
    font-size: 16px;
    color: #E8E8E8;
    margin-top: 28px;
    text-align: left;
    padding-left: 50px;
}

::v-deep .el-button {
    width: 65px;

}

.dialog-footer {
    height: 32px;
    margin-bottom: 18px;
}

::v-deep .el-dialog__body {
    padding: 20px 50px;
}

::v-deep .el-dialog__header{
    padding:10px !important;
}

</style>